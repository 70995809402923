import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPipe } from './search.pipe';
import { MessageComponent } from '../../services/message/message.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SearchPipe, MessageComponent],
  exports: [SearchPipe, MessageComponent]
})
export class HelpersModule {}
