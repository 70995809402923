import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {

  message = {status: null, text: null};

  constructor() {}

  static generateBookingCode(destination) {
    return destination.substr(0, 3) + Math.random().toString(36).substr(7, 6);
  }

  checkNull (obj, except?) {
    const arr = Object.keys(obj);
    const nullValues = arr.filter(entry => {

      if (Array.isArray(except)) {
        if (except.indexOf(entry) !== -1) {
          return false;
        }
      } else {
        if (entry === except) {
          return false;
        }
      }

      return obj[entry] == null;
    });
    return nullValues.length > 0;
  }

  removeNull (obj) {

    const keys = Object.keys(obj);

    keys.map(entry => {
      if (!obj[entry]) {
        delete obj[entry];
      }
    });

    return obj;
  }
}
