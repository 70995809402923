import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alt-menu',
  templateUrl: './alt-menu.component.html',
  styleUrls: [
    './alt-menu.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/css/style.css'
  ]
})
export class AltMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
