import { ViewEncapsulation, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './index.component.html',
  styleUrls: [
    './index.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/css/style.css'
  ]
})
export class IndexComponent implements OnInit {
  sponsorImages = [
    'ace.JPG',
    'ceds.jpg',
    'cu.jpg',
    'edis9.png',
    'electrolife.png',
    'fa.png',
    'hebron-fm.png',
    'muyi.png',
    'ruberyln.png',
    'Saturdays.jpg',
    'walkr.png'
  ];
  constructor() {}

  ngOnInit() {}
}
