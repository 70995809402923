import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css', '../../assets/css/bootstrap.min.css']
})
export class MainMenuComponent extends BaseComponent implements OnInit {
  ngOnInit() {}
}
