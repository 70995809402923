import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-pro-clubs",
  templateUrl: "./pro-clubs.component.html",
  styleUrls: [
    "../../assets/css/bootstrap.min.css",
    "../../assets/css/style.css",
    "./pro-clubs.component.css"
  ]
})
export class ProClubsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
