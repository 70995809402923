import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageService, UtilsService } from '../../services/services';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: "app-subscribe",
  templateUrl: "./subscribe.component.html",
  styleUrls: [
    "./subscribe.component.css",
    "../../assets/css/bootstrap.min.css",
    "../../assets/css/style.css"
  ]
})
export class SubscribeComponent implements OnInit {

  loading: boolean;

  details = {
    email: null
  };

  submitted = false;
	mailChimpEndpoint = 'https://cycles.us7.list-manage.com/subscribe/post-json?u=61fc00c549bee0dcf8c6f04be&amp;id=ec067e1150&';
  
  constructor(
    private http: HttpClient,    
    private messaging: MessageService,) { }


  ngOnInit() {}

	submit() {
    if (!this.details.email) {
      return this.messaging.sendMessage('error', 'Please enter a valid email.');
    }
    
    const params = new HttpParams()
      .set('EMAIL', this.details.email)
      .set('b_123abc123abc123abc123abc123abc123abc', ''); // hidden input name

    const mailChimpUrl = this.mailChimpEndpoint + params.toString();

    this.loading = true;

    // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
    this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
      this.loading = false;
      if (response.result && response.result !== 'error') {
        this.details.email = null;
        return this.submitted = true;
      }
      
      if (response.msg && response.msg.indexOf('already subscribed') > -1) {
        return this.messaging.sendMessage('error', 'You are already subscribed. Thank you!');
      }

      this.messaging.sendMessage('error', response.msg)
    }, error => {
      console.error(error);
      this.loading = false;
      this.messaging.sendMessage('error', 'Your details have been recorded.')
    });
  }
}
