import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-fitness",
  templateUrl: "./fitness.component.html",
  styleUrls: [
    "./fitness.component.css",
    "../../assets/css/bootstrap.min.css",
    "../../assets/css/style.css"
  ]
})
export class FitnessComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
