import "rxjs/add/operator/map";
import "rxjs/add/operator/share";

import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";

@Injectable()
export class UserService {
  URLBase = "users/";

  constructor(public api: ApiService) {}

  postResource(resource, data): any {
    return this._processPost(resource, data);
  }

  updateUser(data) {
    return this._processPut(``, data, { updateUser: true });
  }

  getRides(): any {
    return this._processGet(`ride`);
  }

  getTransactions(): any {
    return this._processGet(`transactions`);
  }

  signUp(data) {
    const seq = this.api.post(`users`, data).share();

    return new Promise((resolve, reject) => {
      seq.subscribe(
        res => {
          if (res.status === "success") {
            sessionStorage.setItem("currentUser", JSON.stringify(res.data));
            resolve(res);
          } else {
            reject(
              res.message ||
                "Technical issue: An error occurred. Please try again."
            );
          }
        },
        err => {
          this._processResult(err.error, resolve, reject);
        }
      );
    });
  }

  login(data) {
    const seq = this.api.post(this.URLBase + "login", data).share();

    return new Promise((resolve, reject) => {
      seq.subscribe(
        res => {
          if (res.status === "success") {
            sessionStorage.setItem("currentUser", JSON.stringify(res.data));
            resolve(res);
          } else {
            reject(
              res.message ||
                "Technical issue: An error occurred. Please try again."
            );
          }
        },
        err => {
          this._processResult(err.error, resolve, reject);
        }
      );
    });
  }

  logout() {
    sessionStorage.removeItem("currentUser");
  }

  storeItem(name, value) {
    sessionStorage.setItem(name, JSON.stringify(value));
  }

  getItemFromSession(name) {
    let item;
    const data = sessionStorage.getItem(name);
    if (!data) {
      return null;
    }
    try {
      item = JSON.parse(data);
    } catch (e) {
      console.error(e);
      item = null;
    }
    return item;
  }

  getCurrentUser() {
    return this.getItemFromSession("currentUser");
  }

  updateCurrentUser(newDetails) {
    sessionStorage.setItem("currentUser", JSON.stringify(newDetails));
  }

  _processPost(endpoint, data, options?) {
    let base = this.URLBase;

    if (options && options.type === "user") {
      base = "users/";
    }

    const seq = this.api.post(base + endpoint, data).share();

    return new Promise((resolve, reject) => {
      seq.subscribe(
        res => {
          this._processResult(res, resolve, reject);
        },
        err => {
          this._processResult(err.error, resolve, reject);
        }
      );
    });
  }

  _processPut(endpoint, data, options?) {
    let base = this.URLBase;

    if (options && options.type === "user") {
      base = "users/";
    }

    const seq = this.api.put(base + endpoint, data).share();

    return new Promise((resolve, reject) => {
      seq.subscribe(
        res => {
          this._processResult(res, resolve, reject, options);
        },
        err => {
          this._processResult(err.error, resolve, reject);
        }
      );
    });
  }

  _processGet(endpoint, options?, params?) {
    let url = this.URLBase;
    if (options && options.index) {
      url = "";
    }
    const seq = this.api.get(url + endpoint, params).share();

    return new Promise((resolve, reject) => {
      seq.subscribe(
        res => {
          this._processResult(res, resolve, reject);
        },
        error => {
          this._processResult(error.error, resolve, reject);
        }
      );
    });
  }

  _processResult(res, resolve, reject, options?) {
    if (!res) {
      return reject("Technical issue: An error occurred. Please try again.");
    }

    const updateUser = options && options.updateUser;

    if (typeof res === "string") {
      try {
        res = JSON.parse(res);
      } catch (e) {
        reject("Technical issue: An error occurred. Please try again.");
        return false;
      }
    }

    if (res.status === "success") {
      if (updateUser) {
        const data = res.data;
        data.token = this.getCurrentUser().token;
        sessionStorage.setItem("currentUser", JSON.stringify(data));
      }
      resolve(res.data);
    } else {
      reject(
        res.message || "Technical issue: An error occurred. Please try again."
      );
    }
  }
}
