import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sports",
  templateUrl: "./sports.component.html",
  styleUrls: [
    "../../assets/css/bootstrap.min.css",
    "../../assets/css/style.css",
    "./sports.component.css"
  ]
})
export class SportsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
