import { ViewEncapsulation, Component, OnInit } from '@angular/core';
import { UserService, MessageService, UtilsService } from '../../services/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/css/style.css'
  ]
})
export class LoginComponent implements OnInit {
  loading: boolean;
  details = {
    email: null,
    password: null
  };

  constructor(
    private utils: UtilsService,
    private user: UserService,
    private messaging: MessageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.user.logout()
  }

  login() {
    if (this.utils.checkNull(this.details)) {
      return this.messaging.sendMessage('error', 'Please complete the form');
    }

    this.loading = true;

    this.user
      .login(this.details)
      .then(data => {
        // redirect to dashboard?
        this.loading = false;
        this.router.navigate(['/user/dashboard']).then(
          res => {},
          err => {
            this.messaging.sendMessage(
              'error',
              err ||
                'Technical issue: You could not be redirected. Please try again.'
            );
          }
        );
      })
      .catch(err => {
        this.loading = false;
        return this.messaging.sendMessage(
          'error',
          err || 'Technical issue: an error occured. Please try again.'
        );
      });
  }
}
