import 'rxjs/add/operator/map';
import 'rxjs/add/operator/share';

import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable()
export class AdminService {
  URLBase = 'admin/';

  constructor(public api: ApiService) {}

  getUsers(): any {
    return this._processGet(`users?accountType=user`);
  }

  getMetrics() {
    return this._processGet('metrics');
  }

  getTickets(): any {
    return this._processGet('tickets');
  }

  updateTicket(data): any {
    return this._processPut(`tickets/${data._id}`, data);
  }

  getRides(): any {
    return this._processGet('rides');
  }

  getBikes(): any {
    return this._processGet('bikes');
  }

  createBike(data): any {
    return this._processPost('bikes', data);
  }

  updateBike(data) {
    return this._processPut(`bikes/${data._id}`, data);
  }

  updateUser(data) {
    return this._processPut(`users/${data._id}`, data);
  }

  login(data, options?) {
    let url = this.URLBase;

    if (options && options.operator) {
      url = 'users/';
    }
    const seq = this.api.post(url + 'login', data).share();

    return new Promise((resolve, reject) => {
      seq.subscribe(
        res => {
          if (res.status === 'success') {
            sessionStorage.setItem('currentUser', JSON.stringify(res.data));
            resolve(res);
          } else {
            reject(
              res.message ||
                'Technical issue: An error occurred. Please try again.'
            );
          }
        },
        err => {
          this._processResult(err.error, resolve, reject);
        }
      );
    });
  }

  logout() {
    sessionStorage.removeItem('currentUser');
  }

  getCurrentUser() {
    let user = null;
    const data = sessionStorage.getItem('currentUser');
    if (!data) {
      return null;
    }
    try {
      user = JSON.parse(data);
    } catch (e) {
      user = null;
    }
    return user;
  }

  uploadStudentsByProgramme(id, url) {
    const seq = this.api
      .post(
        this.URLBase +
          'programme/upload/' +
          id +
          '?external=true&sendEmails=true',
        { csvPath: url }
      )
      .share();

    return new Promise((resolve, reject) => {
      seq.subscribe(
        res => {
          this._processResult(res, resolve, reject);
        },
        err => {
          this._processResult(err.error, resolve, reject);
        }
      );
    });
  }

  _processPost(endpoint, data, options?) {
    let base = this.URLBase;

    if (options && options.type === 'user') {
      base = 'users/';
    }

    const seq = this.api.post(base + endpoint, data).share();

    return new Promise((resolve, reject) => {
      seq.subscribe(
        res => {
          this._processResult(res, resolve, reject);
        },
        err => {
          this._processResult(err.error, resolve, reject);
        }
      );
    });
  }

  _processPut(endpoint, data, options?) {
    let base = this.URLBase;

    if (options && options.type === 'user') {
      base = 'users/';
    }

    const seq = this.api.put(base + endpoint, data).share();

    return new Promise((resolve, reject) => {
      seq.subscribe(
        res => {
          this._processResult(res, resolve, reject);
        },
        err => {
          this._processResult(err.error, resolve, reject);
        }
      );
    });
  }

  _processGet(endpoint) {
    const seq = this.api.get(this.URLBase + endpoint).share();

    return new Promise((resolve, reject) => {
      seq.subscribe(
        res => {
          this._processResult(res, resolve, reject);
        },
        error => {
          this._processResult(error.error, resolve, reject);
        }
      );
    });
  }

  _processResult(res, resolve, reject) {
    if (!res) {
      return reject('Technical issue: An error occurred. Please try again.');
    }
    if (typeof res === 'string') {
      try {
        res = JSON.parse(res);
      } catch (e) {
        reject('Technical issue: An error occurred. Please try again.');
        return false;
      }
    }
    if (res.status === 'success') {
      resolve(res.data);
    } else {
      reject(
        res.message || 'Technical issue: An error occurred. Please try again.'
      );
    }
  }
}
