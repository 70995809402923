import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-bike-share-hotels',
  templateUrl: './bike-share-hotels.component.html',
  styleUrls: [
    '../../../assets/css/bootstrap.min.css',
    '../../../assets/css/style.css',
    './bike-share-hotels.component.css'
  ]
})
export class BikeShareHotelsComponent extends BaseComponent implements OnInit {
  nextimage = 0;
  headText: string;
  $: any;

  ngOnInit() {
    this.$ = this.winRef.nativeWindow.$;

    const texts = ['Commute', 'Quick', 'Healthy'];

    this.runSlideShow(this, texts);
  }
}
