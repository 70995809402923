import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    const container = document.querySelector('.box-container');
    const contents = document.querySelectorAll('.contents');
    const buttons = Array.from(document.querySelectorAll('.button'));

    buttons.forEach(button =>
      button.addEventListener('click', function() {
        container.classList.toggle('right-open');
      })
    );
  }
}
