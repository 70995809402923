import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-bike-share-university',
  templateUrl: './bike-share-university.component.html',
  styleUrls: [
    '../../../assets/css/bootstrap.min.css',
    '../../../assets/css/style.css',
    './bike-share-university.component.css'
  ]
})
export class BikeShareUniversityComponent extends BaseComponent
  implements OnInit {
  nextimage = 0;
  headText: string;
  $: any;

  ngOnInit() {
    this.$ = this.winRef.nativeWindow.$;

    const texts = ['Culture', 'Move', 'People'];

    this.runSlideShow(this, texts);
  }
}
