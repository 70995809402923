import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";

import {
  AdminService,
  ApiService,
  MessageService,
  UserService,
  UtilsService,
  DataService
} from "../services/services";
import { AuthGuard, AdminAuthGuard } from "../services/auth/auth_guard";
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { WindowRef } from "./helpers/window.handler";
import { HelpersModule } from "./helpers/helpers.module";

import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";

import { AppComponent } from "./app.component";
import { IndexComponent } from "./index/index.component";
import { AboutComponent } from "./about/about.component";
import { MobileNavComponent } from "./mobile-nav/mobile-nav.component";
import { AltMenuComponent } from "./alt-menu/alt-menu.component";
import { FooterComponent } from "./footer/footer.component";

import { MainMenuComponent } from "./main-menu/main-menu.component";
import { TermsComponent } from "./terms/terms.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { SubscribeComponent } from "./subscribe/subscribe.component";
import { SportsComponent } from "./sports/sports.component";
import { FeatureComponentComponent } from "./feature-component/feature-component.component";
import { BikeShareUniversityComponent } from "./bike-share/university/bike-share-university.component";
import { BikeShareEstatesComponent } from "./bike-share/estates/bike-share-estates.component";
import { BikeShareComponent } from "./bike-share/bike-share.component";
import { BikeShareHotelsComponent } from "./bike-share/hotels/bike-share-hotels.component";
import { BikeShareCitiesComponent } from "./bike-share/cities/bike-share-cities.component";
import { ContactComponent } from "./contact/contact.component";
import { FitnessComponent } from "./fitness/fitness.component";

import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { ProClubsComponent } from "./pro-clubs/pro-clubs.component";

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    AboutComponent,
    MobileNavComponent,
    AltMenuComponent,
    FooterComponent,
    MainMenuComponent,
    TermsComponent,
    PrivacyComponent,
    SubscribeComponent,
    SportsComponent,
    FeatureComponentComponent,
    BikeShareUniversityComponent,
    BikeShareEstatesComponent,
    BikeShareComponent,
    BikeShareHotelsComponent,
    BikeShareCitiesComponent,
    ContactComponent,
    FitnessComponent,
    LoginComponent,
    SignupComponent,
    ProClubsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    HelpersModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule
  ],
  providers: [
    AdminService,
    ApiService,
    MessageService,
    UserService,
    UtilsService,
    DataService,
    AuthGuard,
    AdminAuthGuard,
    WindowRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
