import { Injectable, OnInit } from '@angular/core';
import {
  AdminService,
  MessageService,
  UtilsService,
  UserService
} from '../services/services';
import { WindowRef } from './helpers/window.handler';

@Injectable()
export abstract class BaseComponent implements OnInit {
  errorResponse = 'Technical issue: an error occured. Please try again.';
  loading: boolean;
  constructor(
    public admin: AdminService,
    public user: UserService,
    public messaging: MessageService,
    public utils: UtilsService,
    public winRef: WindowRef
  ) {}

  ngOnInit() {}

  public loadScripts(scripts: any[]) {
    const existingScripts = document.getElementsByTagName('script');
    const existingScriptsArray = Array.prototype.slice.call(existingScripts);

    let notFound = [];

    if (existingScriptsArray.length) {
      // check if each script already exists
      for (let i = 0; i < scripts.length; i++) {
        if (scripts.hasOwnProperty(i)) {
          const script = scripts[i];

          const found = existingScriptsArray.filter(entry => {
            return (
              entry.getAttribute('src') != null &&
              entry.getAttribute('src').includes(script)
            );
          });

          // doesnt already exist. append to the ones we'll use.
          if (!found.length) {
            notFound.push(script);
          }
        }
      }
    } else {
      notFound = scripts;
    }

    for (let i = 0; i < notFound.length; i++) {
      const node = document.createElement('script');
      node.src = notFound[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  runSlideShow(component, texts, images?) {
    const run = () => {
      if (component.nextimage >= texts.length) {
        component.nextimage = 0;
      }

      component.headText = texts[component.nextimage];

      component.nextimage += 1;

      setTimeout(run, 5000);

      // component
      //   .$('.section-top')
      //   .css(
      //     'background',
      //     `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.4)), url("${
      //       images[component.nextimage]
      //     }")`
      //   )
      //   .fadeIn(200, function() {
      //      setTimeout(run, 5000);
      //   });
    };
    run();
  }
}
