import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";
import { IndexComponent } from "./index/index.component";
import { TermsComponent } from "./terms/terms.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { SportsComponent } from "./sports/sports.component";
import { BikeShareUniversityComponent } from "./bike-share/university/bike-share-university.component";
import { BikeShareEstatesComponent } from "./bike-share/estates/bike-share-estates.component";
import { BikeShareComponent } from "./bike-share/bike-share.component";
import { BikeShareHotelsComponent } from "./bike-share/hotels/bike-share-hotels.component";
import { BikeShareCitiesComponent } from "./bike-share/cities/bike-share-cities.component";
import { FitnessComponent } from "./fitness/fitness.component";
import { ProClubsComponent } from "./pro-clubs/pro-clubs.component";

import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";

const routes: Routes = [
  {
    path: "",
    component: AppComponent,
    children: [
      { path: "", component: IndexComponent },
      { path: "user/logout", redirectTo: "login" },
      { path: "sign-up", component: SignupComponent },
      { path: "login", component: LoginComponent },
      { path: "terms", component: TermsComponent },
      { path: "privacy", component: PrivacyComponent },
      { path: "sports", component: SportsComponent },
      { path: "sports/clubs", component: ProClubsComponent },
      { path: "fitness", component: FitnessComponent },
      {
        path: "bike-share",
        component: BikeShareComponent,
        children: [
          { path: "university", component: BikeShareUniversityComponent },
          { path: "estates", component: BikeShareEstatesComponent },
          { path: "hotels", component: BikeShareHotelsComponent },
          { path: "cities", component: BikeShareCitiesComponent },
          { path: "**", redirectTo: "university" }
        ]
      },
      {
        path: "user",
        loadChildren: "./user/user.module#UserModule"
      },
      {
        path: "admin",
        loadChildren: "./admin/admin.module#AdminModule"
      },
      { path: "**", redirectTo: "" }
    ]
  },
  { path: "**", redirectTo: "" }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
