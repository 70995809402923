import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = sessionStorage.getItem('currentUser');

    if (user) {
      try {
        const userData = JSON.parse(user);
        if (userData) {
          return true;
        }
      } catch (e) {
        console.error(e);
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: state.url }
    });
    return false;
  }
}

@Injectable()
export class AdminAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = sessionStorage.getItem('currentUser');

    if (user) {
      try {
        const userData = JSON.parse(user);
        if (userData && userData.isAdmin) {
          return true;
        }
      } catch (e) {
        console.error(e);
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/admin/login'], {
      queryParams: { returnUrl: state.url }
    });
    return false;
  }
}

@Injectable()
export class OperatorAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = sessionStorage.getItem('currentUser');

    if (user) {
      try {
        const userData = JSON.parse(user);
        if (userData && userData.accountType === 'operator') {
          return true;
        }
      } catch (e) {
        console.error(e);
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/operator/login'], {
      queryParams: { returnUrl: state.url }
    });
    return false;
  }
}

@Injectable()
export class SuperAdminAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = sessionStorage.getItem('currentUser');

    if (user) {
      try {
        const userData = JSON.parse(user);
        if (userData && userData.isAdmin && userData.adminRole === 'super') {
          return true;
        }
      } catch (e) {
        console.error(e);
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/admin/login'], {
      queryParams: { returnUrl: state.url }
    });
    return false;
  }
}
