import { Component, OnInit } from "@angular/core";
import { UserService, MessageService, UtilsService } from '../../services/services';

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: [
    "./contact.component.css",
    "../../assets/css/bootstrap.min.css",
    "../../assets/css/style.css"
  ]
})
export class ContactComponent implements OnInit {
  constructor(
    private utils: UtilsService,
    private user: UserService,
    private messaging: MessageService,
  ) {}

  loading: boolean;
  details = {
    requestType: 'bikeShare',
    fullName: null,
    email: null,
    location: null,
    type: null,
  };

  ngOnInit() {}

  contact() {
    if (this.utils.checkNull(this.details)) {
      return this.messaging.sendMessage('error', 'Please complete the form');
    }

    this.loading = true;

    this.user
      .postResource('contact', this.details)
      .then(data => {
        // redirect to dashboard?
        this.loading = false;
        this.details = {
          requestType: 'bikeShare',
          fullName: null,
          email: null,
          location: null,
          type: null,
        };
        this.messaging.sendMessage('success', 'Your details have been recorded.')
      })
      .catch(err => {
        this.loading = false;
        return this.messaging.sendMessage(
          'error',
          err || 'Technical issue: an error occured. Please try again.'
        );
      });
  }
}
