import { ViewEncapsulation, Component, OnInit } from '@angular/core';
import { UtilsService } from '../../services/helpers/utils.service';
import { UserService, MessageService } from '../../services/services';

@Component({
  selector: 'app-signup',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './signup.component.html',
  styleUrls: [
    './signup.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/css/style.css'
  ]
})
export class SignupComponent implements OnInit {
  registered: boolean;
  loading: boolean;
  details = {
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    confirmPassword: null
  };

  constructor(
    private utils: UtilsService,
    private user: UserService,
    private messaging: MessageService
  ) {}

  ngOnInit() {}

  signuUp() {
    if (this.utils.checkNull(this.details)) {
      return this.messaging.sendMessage('error', 'Please complete the form');
    }

    if (this.details.password !== this.details.confirmPassword) {
      return this.messaging.sendMessage(
        'error',
        'The passwords you entered do not match.'
      );
    }

    this.loading = true;

    this.user
      .signUp(this.details)
      .then(data => {
        // redirect to dashboard?
        this.registered = true;
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
        return this.messaging.sendMessage(
          'error',
          err || 'Technical issue: an error occured. Please try again.'
        );
      });
  }
}
