import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: [
    './mobile-nav.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/css/style.css'
  ]
})
export class MobileNavComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
